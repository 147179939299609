var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.flag == 2)?_c('div',{staticClass:"videoions"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"titles"},[_vm._v("了解更多产品细节 >>")])]):(_vm.flag == 3)?_c('div',{staticClass:"videoions"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"titles"},[_vm._v("了解更多产品细节 >>")])]):_c('div',{staticClass:"videoions"},[_vm._m(5),_c('div',{staticStyle:{"width":"100%","height":"368px","background":"#fcfcfc"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require('../assets/indust/' + _vm.flagone[_vm.flag].img),"alt":""}})]),_c('div',{staticClass:"titles"},[_vm._v("了解更多产品细节 >>")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messages"},[_c('label',{staticClass:"borders"}),_vm._v(" 智能硬件 "),_c('label',{staticClass:"borders"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"boximg"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/x1.png","alt":""}})]),_c('div',{staticClass:"boxtxt"},[_c('div',{staticStyle:{"margin":"10px 0","color":"#121212","font-size":"20px"}},[_vm._v("可燃气体探测器")]),_c('div',{staticStyle:{"color":"#747575","font-size":"16px"}},[_vm._v("·NB-IoT通信，覆盖范围广，传输稳定； ·APP实时读取水压数据并上传至云平台； ·设备内置到消防栓中，安全便捷； ·超长时间待机（每天上报一次，可待机3年）")])])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"boximg"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/x2.png","alt":""}})]),_c('div',{staticClass:"boxtxt"},[_c('div',{staticStyle:{"margin":"10px 0","color":"#121212","font-size":"20px"}},[_vm._v("可燃气体报警器")]),_c('div',{staticStyle:{"color":"#747575","font-size":"16px"}},[_vm._v("·NB-IoT通信，覆盖范围广，传输稳定； ·测控多种可燃气体浓度、成分、收集并传递信息； ·低功耗，气体浓度异常声光报警")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list"},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"boximg"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/x3.png","alt":""}})]),_c('div',{staticClass:"boxtxt"},[_c('div',{staticStyle:{"margin":"10px 0","color":"#121212","font-size":"20px"}},[_vm._v("烟雾报警器")]),_c('div',{staticStyle:{"color":"#747575","font-size":"16px"}},[_vm._v("·NB-IoT通信，覆盖范围广，传输稳定； ·烟雾数据上传云平台；实时监测烟雾浓度； ·设备可吸顶快速安装； ·低功耗模块，正常使用可达3年")])])]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"boximg"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/x4.png","alt":""}})]),_c('div',{staticClass:"boxtxt"},[_c('div',{staticStyle:{"margin":"10px 0","color":"#121212","font-size":"20px"}},[_vm._v("CO探测器")]),_c('div',{staticStyle:{"color":"#747575","font-size":"16px"}},[_vm._v("·NB-IoT通信，覆盖范围广，传输稳定； ·测控CO气体浓度，收集并传递信息； ·CO浓度异常声光报警")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messages"},[_c('label',{staticClass:"borders"}),_vm._v(" 智能硬件 "),_c('label',{staticClass:"borders"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"boxs"},[_c('div',{staticClass:"boximgs"},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/x5.png","alt":""}})]),_c('div',{staticClass:"boxtxts"},[_c('div',{staticStyle:{"margin":"10px 0","color":"#121212","font-size":"20px"}},[_vm._v("牛更旺")]),_c('div',{staticStyle:{"color":"#747575","font-size":"16px"}},[_vm._v("超大容量，超长待机； 体积小巧，重量超轻； 政策支持 贷款必备； 抗冻防寒 不易损坏。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"messages"},[_c('label',{staticClass:"borders"}),_vm._v(" 智能硬件 "),_c('label',{staticClass:"borders"})])
}]

export { render, staticRenderFns }