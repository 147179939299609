var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.flag == 0)?_c('div',{staticClass:"videoions"},[_vm._m(0),(_vm.dex == 2)?_c('div',[_vm._m(1)]):_c('div',[_c('div',{staticClass:"product",staticStyle:{"margin-top":"50px"}},[_c('div',{staticStyle:{"width":"50%","height":"338px","background":"#fcfcfc"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require('../assets/product/' + _vm.tabarr[_vm.dex].img1),"alt":""}})]),_c('div',{staticClass:"righttit"},[_c('div',{staticClass:"titlea"},[_vm._v(_vm._s(_vm.tabarr[_vm.dex].title1))]),_c('div',{staticClass:"txts"},[_vm._v(" "+_vm._s(_vm.tabarr[_vm.dex].txt3)+" ")])])]),_c('div',{staticClass:"product",staticStyle:{"margin-top":"50px"}},[_c('div',{staticClass:"righttit"},[_c('div',{staticClass:"titlea"},[_vm._v(_vm._s(_vm.tabarr[_vm.dex].title2))]),_c('div',{staticClass:"txts"},[_vm._v(" "+_vm._s(_vm.tabarr[_vm.dex].txt3)+" ")])]),_c('div',{staticStyle:{"width":"50%","height":"338px","background":"#fcfcfc"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require('../assets/product/' + _vm.tabarr[_vm.dex].img2),"alt":""}})])]),_c('div',{staticClass:"product",staticStyle:{"margin-top":"50px"}},[_c('div',{staticStyle:{"width":"50%","height":"338px","background":"#fcfcfc"}},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":require('../assets/product/' + _vm.tabarr[_vm.dex].img3),"alt":""}})]),_c('div',{staticClass:"righttit"},[_c('div',{staticClass:"titlea"},[_vm._v(_vm._s(_vm.tabarr[_vm.dex].title3))]),_c('div',{staticClass:"txts"},[_vm._v(" "+_vm._s(_vm.tabarr[_vm.dex].txt3)+" ")])])])])]):(_vm.flag == 1)?_c('div',{staticClass:"videoions"},[_vm._m(2),_vm._m(3),_vm._m(4)]):(_vm.flag == 4)?_c('div',{staticClass:"videoions"},[_vm._m(5),_vm._m(6)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message"},[_c('label',{staticClass:"borders"}),_vm._v(" 方案场景 "),_c('label',{staticClass:"borders"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('div',[_c('img',{staticStyle:{"width":"285px","height":"160px"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/c31.png","alt":""}}),_c('div',{staticClass:"titall"},[_vm._v("政府机关车队管理")])]),_c('div',[_c('img',{staticStyle:{"width":"285px","height":"160px"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/c32.png","alt":""}}),_c('div',{staticClass:"titall"},[_vm._v("智慧园区车辆管理")])]),_c('div',[_c('img',{staticStyle:{"width":"285px","height":"160px"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/c33.png","alt":""}}),_c('div',{staticClass:"titall"},[_vm._v("企事业单位车队管理")])]),_c('div',[_c('img',{staticStyle:{"width":"285px","height":"160px"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/c34.png","alt":""}}),_c('div',{staticClass:"titall"},[_vm._v("旅游客车管理")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message"},[_c('label',{staticClass:"borders"}),_vm._v(" 方案场景 "),_c('label',{staticClass:"borders"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('div',{staticStyle:{"margin":"10px"}},[_c('img',{staticStyle:{"width":"100%","height":"324px"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/c11.png","alt":""}}),_c('div',{staticClass:"titalls"},[_vm._v("社区垃圾分类管理")])]),_c('div',{staticStyle:{"margin":"10px"}},[_c('img',{staticStyle:{"width":"100%","height":"324px"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/c12.png","alt":""}}),_c('div',{staticClass:"titalls"},[_vm._v("智慧社区打造")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('div',{staticStyle:{"margin":"10px"}},[_c('img',{staticStyle:{"width":"100%","height":"324px"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/c13.png","alt":""}}),_c('div',{staticClass:"titalls"},[_vm._v("智慧物业管理")])]),_c('div',{staticStyle:{"margin":"10px"}},[_c('img',{staticStyle:{"width":"100%","height":"324px"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/c14.png","alt":""}}),_c('div',{staticClass:"titalls"},[_vm._v("居民区安防管理")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message"},[_c('label',{staticClass:"borders"}),_vm._v(" 方案场景 "),_c('label',{staticClass:"borders"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('div',[_c('img',{staticStyle:{"width":"388px","height":"238px"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/c21.png","alt":""}}),_c('div',{staticClass:"titall"},[_vm._v("资产密集型企业资产管理如电力、石化、矿业、航运等行业")])]),_c('div',[_c('img',{staticStyle:{"width":"388px","height":"238px"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/c22.png","alt":""}}),_c('div',{staticClass:"titall"},[_vm._v("资产高价型企业资产管理如银行、珠宝、贵金属、汽车等行业")])]),_c('div',[_c('img',{staticStyle:{"width":"388px","height":"238px"},attrs:{"src":"https://advertisement.billicomm.cn/official/website/assets/product/c23.png","alt":""}}),_c('div',{staticClass:"titall"},[_vm._v("航运、物流货物定位追踪管理 ")])])])
}]

export { render, staticRenderFns }