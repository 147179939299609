<template>
  <div id="service">

    <div style="width: 100%; background: #fcfcfc; min-height:560px;" :style="{ height: height + 'px' }">
      <img src="https://advertisement.billicomm.cn/official/website/assets/index/banner3.png"
        style="width: 100%; height: 100%" />
    </div>

    <div class="service_box">

<!--      <div class="service_title">-->
<!--        <button class="busicp-button" @click="goClick('announcement')">杭州佰鹿信息科技有限公司物联网集成服务项目招标公告</button>-->
<!--      </div>-->

      <div class="service_title">
        招标信息公示
      </div>
      <div class="service_title">
        <button class="clickable-text" @click="goClick('announcement')">杭州佰鹿信息科技有限公司物联网集成服务项目招标公告</button>
      </div>

<!--      <div class="service_title">-->
<!--        <div  class="clickable-text2" @click="goClick('announcement')">杭州佰鹿信息科技有限公司物联网集成服务项目招标公告</div>-->
<!--      </div>-->
      <!-- 联系我们 -->
      <div class="service_title">
        真诚沟通，认真聆听，佰鹿科技期待与您携手共赢
      </div>
      <div class="service_file">
        欢迎您对我司情况、产品信息等问题进行咨询，我们都将给予详尽的答复
      </div>
<!--      <div class="formlist">-->
<!--        <el-form :model="numberValidateForm" ref="numberValidateForm" label-width="460px" class="demo-ruleForm">-->
<!--          <el-form-item label="姓名" prop="name">-->
<!--            <el-input type="name" v-model.number="numberValidateForm.name" autocomplete="off"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="联系电话" prop="phone" :rules="[-->
<!--            { required: true, message: '电话不能为空' },-->
<!--            { type: 'number', message: '年龄必须为数字值' },-->
<!--          ]">-->
<!--            <el-input type="phone" v-model.number="numberValidateForm.phone" autocomplete="off"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="意向产品" prop="product">-->
<!--            <el-input type="product" v-model.number="numberValidateForm.product" autocomplete="off"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="公司名称" prop="company">-->
<!--            <el-input type="company" v-model.number="numberValidateForm.company" autocomplete="off"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="咨询内容" prop="consult">-->
<!--            <el-input type="consult" v-model.number="numberValidateForm.consult" autocomplete="off"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="邮箱" prop="email">-->
<!--            <el-input type="email" v-model.number="numberValidateForm.email" autocomplete="off"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item>-->
<!--            <el-button style=" width: 280px;position:relative;right:100px;" type="primary" size="medium"-->
<!--              @click="submitForm('numberValidateForm')">提交-->
<!--            </el-button>-->
<!--          </el-form-item>-->
<!--        </el-form>-->

<!--      </div>-->

    </div>
    <img src="https://advertisement.billicomm.cn/official/website/assets/index/last.png"
      style="width: 100%; height: 100%;position:relative;top:4px;" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      numberValidateForm: {
        product: "",
        name: "",
        phone: "",
        company: "",
        consult: "",
        email: ""
      },
      height: document.documentElement.clientWidth / 4.47
    };
  },
  mounted() {
    window.onresize = () => {
      this.height = document.documentElement.clientWidth / 4.47
    }
  },
  methods: {
    goClick(item) {
      this.$router.push({ name: item })
    },

    submitForm() {
      console.log(this.numberValidateForm);
      if (this.numberValidateForm.phone) {
        if (!(/^1[345789]\d{9}$/.test(this.numberValidateForm.phone))) {
          this.$message({
            type: 'warning',
            message: '手机号格式有误!'
          });
          return false;
        } else if (this.numberValidateForm.email) {
          if (!(/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.numberValidateForm.email))) {
            this.$message({
              type: 'warning',
              message: '邮箱格式有误!'
            });
            return false;
          }
        }
        this.$message({
          type: 'success',
          message: '提交成功!'
        });
        // this.$api.cooperation(this.numberValidateForm).then(res => {
        //   this.$message({
        //     type: 'success',
        //     message: '提交成功!'
        //   });
        // }).catch(err => {
        //   console.log(err)
        // })
      } else {
        this.$message({
          type: 'warning',
          message: '请完善信息!'
        });
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss">
.el-input__inner {
  width: auto !important;

}

.el-form-item {
  margin: 22px;

}

.el-button--primary {
  margin-left: 50px;
}

.service_title {
  text-align: center;
  width: 100%;
  margin: auto;
  color: #2b2b34;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 10px;
}

.service_box {
  width: 1200px;
  padding: 35px;
  margin: auto;
  box-sizing: border-box;
}

.box_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.content_img {
  width: 315px;
  height: 315px;
  background: #d3dce6;
  margin-bottom: 16px;
}

.content_font {
  font-size: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
}

.service_file {
  text-align: center;
  width: 100%;
  margin: auto;
  color: #2b2b34;
  font-size: 14px;
  margin-top: 10px;
}

.busicp-button { background-image: url('https://advertisement.billicomm.cn/official/website/assets/index/busi_cp_btn.png');
  background-size: cover; background-position: center; width: 500px; height: 100px; border: none; color: white;
  font-size: 16px; text-align: center; line-height: 100px; cursor: pointer;
}

.clickable-text { text-align: center; text-decoration: underline; color: blue; cursor: pointer; width: 500px; height: 500px;font-size: 18px;}
.clickable-text2 { text-decoration: underline; color: blue; cursor: pointer; }
</style>
